import { post } from '../adapters/appSheetAdapter';
import { IAreaRoot, IAreaRow } from '../models/area-model';
import { ITrackingRoot } from '../models/tracking-model';
import { apiPath } from './path-base';

export async function tracking(OfferIDX: string) {
  try {
    const payload: ITrackingRoot = {
      Action: 'Find',
      Properties: { Locale: 'en-US', Timezone: 'SE Asia Standard Time', RunAsUserEmail: 'sattavut.su@cjexpress.co.th' },
      Rows: [{ OfferIDX: OfferIDX }],
    };
    const response = await post(apiPath.appSheet.tracking.url, payload);
    return response;
  } catch (error) {
    throw error;
  }
}

export async function offerArea(data: IAreaRow) {
  try {
    const payload: IAreaRoot = {
      Action: 'Add',
      Properties: { Locale: 'en-US', Timezone: 'SE Asia Standard Time', RunAsUserEmail: 'sattavut.su@cjexpress.co.th' },
      Rows: [{ ...data }],
    };    
    const response = await post(apiPath.appSheet.area.url, payload);
    return response;
  } catch (error) {
    throw error;
  }
}
