import { useState,useEffect } from 'react';
import { Button, Box, Typography, Container } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import Image from '../../components/Image';
import IconSuccess from '../../assets/icon-success.png';


const SuccessPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [typePage, setTypePage] = useState('');
  const [statusError, setStatusError] = useState(false);
  
  
  useEffect(() => {
    // console.log(location.state);
    !location.state && navigate('/error'); 
    if(location.state && location.state.data.SiteOffer_Status === "ไม่ผ่านการประเมิน"){
      setStatusError(true);
    }
    if(location.state && location.state.type === 'step'){
      console.log('step');
      setTypePage('step');
    } else if (location.state && location.state.type === 'tracking') {
      console.log('tracking')
      setTypePage('tracking')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container sx={{backgroundColor:'#FAFCFD', p:2}}>
        <Box display='flex' sx={{height:'100%', flexFlow:'column wrap', justifyContent:'center', alignItems:'center', textAlign:'center', gap:'20px'}}>
            <Image src={IconSuccess} alt='บันทึกสำเร็จ' height="148" />
            {typePage === 'step' && (
              <>
                <Typography component='h2' sx={{fontSize:24, mt:2, fontWeight:500, color:'#0A582D'}}>บันทึกข้อมูลสำเร็จ</Typography>
                <Typography sx={{color:'rgba(0, 0, 0, 0.7)', fontSize:16,fontWeight:500, lineHeight:'24px'}}>
                    ขอบคุณที่ให้ความสนใจในการเสนอพื้นที่เช่าให้กับซีเจ<br />
                    ทางบริษัทจะติดต่อกลับหาท่านผ่านทาง E-mail ที่ท่านให้มาภายใน 5 วันทำการ
                </Typography>
              </>
            )}
            {typePage === 'tracking' && (
              <>
                <Typography sx={{color:'#000', fontSize:16,fontWeight:500, mt:2, lineHeight:'24px'}}>
                สถานะการเสนอพื้นที่เช่าของท่าน
                </Typography>
                <Typography component='h2' sx={{fontSize:24, m:'-10px 0', fontWeight:500, color:statusError?'#D21000':'#0A582D'}}>
                  {location.state && location.state.data.SiteOffer_Status}
                </Typography>
                <Typography sx={{color:'rgba(0, 0, 0, 0.7)', fontSize:16,fontWeight:500, lineHeight:'24px'}}>
                หากมีข้อสงสัยกรุณาติดต่อ Call Center 02 026 2246
                </Typography>
              </>
            )}
            <Button component={Link} to="/" variant="contained" size="large" sx={{width:{xs:'198px',md:'191px'}, height:{xs:'40px',md:'44px'}}}>
            กลับสู่เมนูหลัก
            </Button>
        </Box>
    </Container>
  )
}

export default SuccessPage