import { useState,useEffect } from 'react';
import { Button, Box, Typography, Container } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';

import Image from '../../components/Image';
import IconError from '../../assets/icon-error.png';


const ErrorPage = () => {
  const location = useLocation();
  const [typePage, setTypePage] = useState('');
  
  
  useEffect(() => {
    // !location.state && navigate('/error'); 
    if(location.state && location.state.type === 'step'){
      console.log('step');
      setTypePage('step');
    } else if (location.state && location.state.type === 'tracking') {
      console.log('tracking')
      setTypePage('tracking')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container sx={{backgroundColor:'#FAFCFD', p:2}}>
        <Box display='flex' sx={{height:'100%', flexFlow:'column wrap', justifyContent:'center', alignItems:'center', textAlign:'center', gap:'20px'}}>
            <Image src={IconError} alt='บันทึกข้อมูลไม่สำเร็จ' height="148" />
            {typePage === 'step' ? (
            <Typography component='h2' sx={{fontSize:24, mt:2, fontWeight:500, color:'#D21000'}}>บันทึกข้อมูลไม่สำเร็จ</Typography>
            ) : (
            <Typography component='h2' sx={{fontSize:24, mt:2, fontWeight:500, color:'#D21000'}}>เกิดข้อผิดพลาด</Typography>
            )}
            {typePage ? (
              <Typography sx={{color:'rgba(0, 0, 0, 0.7)', fontSize:16,fontWeight:500, lineHeight:'24px'}}>
                  Error 400 : กรุณาลองใหม่อีกครั้งในภายหลัง หรือติดต่อ Call Center 02 026 2246
              </Typography>
            ) : (
              <Typography sx={{color:'rgba(0, 0, 0, 0.7)', fontSize:16,fontWeight:500, lineHeight:'24px'}}>
                  Error 404 : ขออภัยค่ะ! ไม่พบหน้าที่คุณต้องการ
              </Typography>
            )}

            {typePage === 'step' ? (
              <Button component={Link} to="/step1" variant="outlined" size="large" sx={{width:{xs:'198px',md:'191px'}, height:{xs:'40px',md:'44px'}}}>
              กลับสู่หน้าแรก
              </Button>
            ) : (
              <Button component={Link} to="/" variant="contained" size="large" sx={{width:{xs:'198px',md:'191px'}, height:{xs:'40px',md:'44px'}}}>
              กลับสู่เมนูหลัก
              </Button>
            )}
            
        </Box>
    </Container>
  )
}

export default ErrorPage