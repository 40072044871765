import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import Header from './Header';
import Image from '../components/Image';
import Logo from '../assets/logo.png';

export default function Navbar() {
    return (
      <>
      <AppBar position='static'  elevation={0} component="nav">
        <Toolbar sx={{justifyContent: 'center', alignItems: 'center', background: '#119B4E',boxShadow: '0px 2px 10px 4px rgba(0, 107, 107, 0.15)', color: 'white', height: '64px'}}>
          <Box display='block' component='i' sx={{mr: 2}}><Image src={Logo} height='45' style={{display: 'block'}} /></Box>
          <Typography component='h1' sx={{fontWeight: '600', fontSize: {xs:18, md:24}}}>บริษัท ซี.เจ. เอ็กซ์เพลสกรุ๊ป จำกัด</Typography>
        </Toolbar>
      </AppBar>

      <Header />
      </>
    );
  }