export const env: any = {
  appSheet: {
    url: process.env.REACT_APP_APPSHEET_URL,
    timeout: process.env.REACT_APP_APPSHEET_TIME_OUT,
    secretKey: process.env.REACT_APP_APPSHEET_KEY
  },
  googleMap: {
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY,
  },
  googleCaptcha: {
    siteKey: process.env.REACT_APP_SITE_KEY,
    secretKey: process.env.REACT_APP_SECRET_KEY,
  }
};

