/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Button, Grid, Typography, Box, TextField, Select, MenuItem } from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppSelector,useAppDispatch } from '../../store/store';
import { ColRightStep } from '../../styles/styled';
import Image from '../../components/Image';
import CustomStepBar from './components/CustomStepBar';
import Placeholder from '../../components/Placeholder';
import { step3ValidationSchema } from '../../utils/validateSchema';
import { updateInputForm } from '../../store/slices/input-form-slice';

import IconArrow from '../../assets/icon-arrow-btn.png';
import AreaCondition from './components/AreaCondition';

interface IFormInputs {
  expectedRent: string;
  fromChannel: string;
  fromOtherChannel: string;
}

const StepThreePage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const inputSelector = useAppSelector(state => state.inputFormSlice)

  useEffect(() => {
    if(!inputSelector.firstName || !inputSelector.lastName || !inputSelector.telephone || !inputSelector.email || !inputSelector.offerType || !inputSelector.landWidth || !inputSelector.landDepth || !inputSelector.landAreaSQM) {
      navigate('/');
    }
    
  }, [])
  
  
  const {
    register,
    watch,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInputs>({
    resolver: yupResolver(step3ValidationSchema),
  });

  const handleChangeFormat = (event:any) => {
    let newValue = event.target.value;
    newValue = newValue.replace(/\D/g, '');
    newValue = newValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setValue('expectedRent', newValue);
  };
  
  const watchfromChannel = watch("fromChannel");

  const onSubmit = (data: IFormInputs) => {
    console.log(data);
    dispatch(updateInputForm(data));
    navigate('/summary');
  };

  useEffect(() => {
    // console.log(inputSelector.expectedRent, inputSelector.fromChannel, inputSelector.fromOtherChannel)
    setValue('expectedRent', inputSelector.expectedRent);
    setValue('fromChannel', inputSelector.fromChannel);
    setValue('fromOtherChannel', inputSelector.fromOtherChannel);
    
  }, [])

  useEffect(() => {
    getValues('fromChannel') !== 'อื่นๆ/Other' && setValue('fromOtherChannel', '');
    
  }, [watchfromChannel])

  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} md={5} sx={{ display: { xs: 'none', sm: 'none', md: 'flex' } }}>
          <AreaCondition />
        </Grid>
        <Grid item xs={12} md={7}>
          <form onSubmit={handleSubmit(onSubmit)} style={{height:'100%'}}>
          <ColRightStep>
            <Typography component="h2">แบบฟอร์มสำหรับเสนอพื้นที่เช่า</Typography>

            <CustomStepBar step={3} />

            <Grid
                container
                rowSpacing={2}
                columnSpacing={{ xs: 2, sm: 2, lg: 6 }}
                sx={{ width:'350px!important', maxWidth: '100%', mv: 'auto'}}
              >
                <Grid item xs={12} sx={gridStyle}>
                  <Label>ค่าเช่าที่คาดหวังต่อเดือน</Label>
                  <TextField 
                  {...register('expectedRent')} 
                  error={errors.expectedRent ? true : false} 
                  sx={textFieldStyle} 
                  inputProps={{ maxLength: 10 }} 
                  onChange={handleChangeFormat} />
                  <Typography component="p" sx={errorBox}>{errors.expectedRent && errors.expectedRent.message}</Typography>
                </Grid>
                <Grid item xs={12} sx={gridStyle}>
                  <Label>ช่องทางที่ได้รับข้อมูล</Label>
                  <Select 
                  {...register('fromChannel')} 
                  error={errors.fromChannel ? true : false} 
                  sx={textFieldStyle}
                  value={getValues('fromChannel')? getValues('fromChannel'): ''}
                  displayEmpty
                  >
                    <MenuItem disabled value=''>
                      <Placeholder text="กรุณาเลือก" />
                    </MenuItem>
                    <MenuItem key={'0'} value='Email'>
                    Email
                    </MenuItem>
                    <MenuItem key={'1'} value='Call center'>
                    Call center
                    </MenuItem>
                    <MenuItem key={'2'} value='Facebook'>
                    Facebook
                    </MenuItem>
                    <MenuItem key={'3'} value='CJ Employee'>
                    CJ Employee
                    </MenuItem>
                    <MenuItem key={'4'} value='อื่นๆ/Other'>
                    อื่นๆ/Other
                    </MenuItem>
                  </Select>
                  <Typography component="p" sx={errorBox}>{errors.fromChannel && errors.fromChannel.message}</Typography>
                </Grid>
                {watchfromChannel && watchfromChannel === 'อื่นๆ/Other' && (
                <Grid item xs={12} sx={gridStyle}>
                  <Label>โปรดระบุ</Label>
                  <TextField {...register('fromOtherChannel')} error={errors.fromOtherChannel ? true : false} sx={textFieldStyle}
                  disabled={watchfromChannel && watchfromChannel !== 'อื่นๆ/Other' ? true : false }
                   />
                  <Typography component="p" sx={errorBox}>{errors.fromOtherChannel && errors.fromOtherChannel.message}</Typography>
                </Grid>
                )}
            </Grid>

            <Box
                display="flex"
                sx={{
                  gap: '10px',
                  flexFlow: 'row nowrap',
                  justifyContent: 'center',
                  marginTop: 4,
                  position: 'sticky',
                  bottom: 0,
                  width: '100%',
                  boxShadow: '0 -12px 0 -7px rgba(250, 252, 253, 0.75)',
                  backgroundColor: '#FAFCFD',
                }}
              >
              <Button component={Link} to="/step2" variant="outlined">
                ย้อนกลับ
              </Button>
              <Button
                type='submit'
                variant="contained"
                endIcon={<Image src={IconArrow} width="32" />}
              >
                ถัดไป
              </Button>
            </Box>
          </ColRightStep>
          </form>
        </Grid>
      </Grid>
    </>
  );
};

const Label = styled('label')(({ theme }) => ({
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '18px',
  marginBottom: '5px',
  color: theme.palette.primary.dark,
}));

const gridStyle = {
  display: 'flex',
  flexFlow: 'column wrap',
};

const textFieldStyle = {
  '& input': {
    padding: '0 5px',
    lineHeight: '24px',
    height: '24px',
    maxWidth: '100%',
    fontSize: '14px',
  },
  '& .MuiSelect-select': {
    padding: '0 0 0 5px',
    fontSize: '14px',
  },
};

const errorBox = {
  fontSize: '12px',
  color: '#EB3223',
  lineHeight: '15px',
  marginTop: '5px',
  minHeight: '15px',
};

export default StepThreePage;
