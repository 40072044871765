import { Typography, Box } from '@mui/material';
import { ColLeftStep, ListArea } from '../../../styles/styled';


const AreaCondition = () => {
  return (
    <ColLeftStep>
      <Typography component="h2">เงื่อนไขการเสนอพื้นที่ตั้งร้าน CJ MORE</Typography>
      <ul style={{ listStyle: 'inside disc' }}>
        <ListArea>ขนาดพื้นที่ขั้นต่ำ 3 งาน หรือ 300 ตารางวา (1,200 ตารางเมตร) </ListArea>
        <ListArea>หน้ากว้างขั้นต่ำ 20 เมตร</ListArea>
        <ListArea>เป็นทำเลที่มีศักยภาพตรงความต้องการของทางบริษัท</ListArea>
        <Box component="p" sx={{ fontSize: '16px', fontWeight: 500, color: 'rgba(0, 0, 0, 0.7)', marginTop: '5px' }}>
          โดยหลังกรอกรายละเอียดเสร็จสมบูรณ์ จะติดต่อกลับภายใน 5 วันทำการ
        </Box>
      </ul>
    </ColLeftStep>
  );
};

export default AreaCondition;
